// app v4
import './taskConfig'; // описание задачи
import App from './App.vue'; // главное окно
import { runStackApp } from '@/stackEngine';
import './prepareTask'; // загрузка дополнительных данных по задаче
import './prepareSelections'; // загрузка выборок
import './prepareViews'; // загрузка views
import './prepareStore'; // загрузка индивидуального хранилища задачи

runStackApp(App);
