import imagePackCommon from '@/styles/imagePack_common';
import { setTaskConfig } from '@/stackEngine';

const taskConfig: Task = {
  prefix: 'iddqd',
  name: 'tackchange',
  description: 'Переключение программ',
  htmltitle: '<b>Переключение программ</b>',
  icon: '$vuetify.icons.home',
  imagePack: { ...imagePackCommon },
  externalTask: true,
};

setTaskConfig(taskConfig);